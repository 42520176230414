import { PagePerformedByRequestDTO } from './page-performed-by-request-dto';

export interface ReadWorkRequestDTO extends PagePerformedByRequestDTO {
    userEmail?: string;
    userFullName?: string;
    userStatus?: string;
    userType?: string;
    userRoleName?: string;
    userRoleStatus?: string;
    userContextName?: string;
    userAssociationValues?: string;
    userAssociationType?: string;
    userProfileName?: string;
    userApprovalStatus?: string;
    userApprovalActionFromDt?: Date;
    userApprovalActionToDt?: Date;
    aging?: string;
    approverEmail?: string;
    approverRoleName?: string;
    requestId?: string;
    requestFromDt?: Date;
    requestToDt?: Date;
    myWorkFilter?: string;
    filterText?: string;
    languageCd?: string;
}
