import { Component, Input } from '@angular/core';

@Component({
    selector: 'idb-ui-blocker',
    template: `<p-blockUI [blocked]="loading">
        <ng-template pTemplate="blocker">
            <div class="flex flex-column lign-items-center">
                <div class="flex flex-row align-items-center">
                    <i class="pi pi-spinner p-icon-spin idb-loading-spsin" style="font-size: 10rem; color: white"></i>
                </div>
            </div>
        </ng-template>
    </p-blockUI>`,
    styleUrls: [],
})
export class UIBlockerComponent {
    @Input()
    loading = false;
}
