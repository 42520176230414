import { UserContextRoleIdDTO } from './user-context-role-id-dto';

export interface UserContextRoleProfileDTO extends UserContextRoleIdDTO {
    scopeValues?: string;
    scopeDisplayValues?: string;
    contextProfileCd?: string;
    idbApps?: string;
    profileName?: string;
    profileStatus?: string;
    grantedByUserEmail?: string;
    grantedDt?: string;
    assignedToRoleOrUserEmail?: string;
    pendingRequestAssignedTillDt?: Date;
    recertifiedUserEmail?: string;
    recertifiedDt?: Date;
    activationDt?: Date;
    deniedReason?: string;
    canEdit?: boolean;
    canApprove?: boolean;
    canDeny?: boolean;
    userRoleContextScopeId?: number;
}
