export interface WorkDTO {
    userEmail?: string;
    userFullName?: string;
    userStatus?: string;
    userType?: string;
    userRoleCd?: string;
    userRoleName?: string;
    userRoleStatus?: string;
    userContextCd?: string;
    userContextName?: string;
    userRoleContextId?: number;
    userAssociationValues?: string;
    userAssociationDisplayValues?: string;
    userAssociationType?: string;
    userRoleContextProfileId?: number;
    userProfileCd?: string;
    userProfileName?: string;
    userApprovalStatus?: string;
    userApprovalStatusName?: string;
    userApprovalActionDt?: Date;
    aging?: string;
    approverEmail?: string;
    approverRoleName?: string;
    requestId?: string;
    requestDt?: Date;
    canEdit?: boolean;
}
