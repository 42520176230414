import { AnyKey } from '../data';
import { ENTranslation } from './en';
import { ESTranslation } from './es';
// import { FRTranslation } from './fr';
import { PTTranslation } from './pt';

import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

const Translations = {
    en: ENTranslation,
    es: ESTranslation,
    // fr: FRTranslation,
    pt: PTTranslation,
};

export class EAPSTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of((Translations as any)[lang as AnyKey] || {});
    }
}
