import { PerformedByRequestDTO } from './performed-by-request-dto';

export interface SaveUserContextRoleProfileRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    contextCd?: string;
    roleCd?: string;
    contextProfileCd?: string;
    userRoleContextProfileId?: number;
    profileStatus?: string;
    deniedReason?: string;
    keepInMyWorkbasketTill?: string;
    disableAutomaticUpdate?: boolean;
    profilePermissionsList?: string;
    isInternal?: boolean;
}
