import { PerformedByRequestDTO } from './performed-by-request-dto';
import { RequestDTO } from './request-dto';
import { TypeScopeDTO } from './type-scope-dto';

export interface SaveUserContextRoleRequestRequestDTO extends RequestDTO {
    userEmail?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    secondaryEmail?: string;
    preferredLanguageCd?: string;
    primaryPhone?: string;
    mobilePhone?: string;
    fax?: string;
    roleCd?: string;
    contextCd?: string;
    scopes?: TypeScopeDTO[];
    deleteRole?: boolean;
}

export interface SaveUserContextRoleExternalRequestRequestDTO extends SaveUserContextRoleRequestRequestDTO {
    requestKey?: string;
}

export interface SaveUserContextRoleInternalRequestRequestDTO extends SaveUserContextRoleRequestRequestDTO, PerformedByRequestDTO {}
