import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { cloneDeep } from 'lodash-es';

import {
    MetaService,
    OptionsMapDTO,
    isAssigned,
    ReadContextRoleScopePickerRequestDTO,
    ContextRoleScopeComponentDTO,
    ContextRoleScopeComponentItemDTO,
    ReadContextRoleScopePickerValidationRequestDTO,
    ContextRoleService,
    TypeScopeDTO,
    AnyKey,
    lastOrDefault,
    ReadContextRoleScopePickerRequestBaseDTO,
    moveFieldToFilter,
    GlobalsService,
    BaseComponent,
    UnknownErrorCallingAPIMessage,
    isEmpty,
    UserAccountInformationDTO,
} from '../../data';

interface ScopeField {
    component?: ContextRoleScopeComponentDTO;
    value?: ContextRoleScopeComponentItemDTO[];
    isValidated?: boolean;
}

export enum ContextRoleScopePickerKind {
    Scope = 'scope',
    Affiliation = 'affiliation',
    Exception = 'exception',
}

export interface PickerTypeScopeDTO extends TypeScopeDTO {
    field1DisplayValue?: string;
    field2DisplayValue?: string;
    field3DisplayValue?: string;
    field4DisplayValue?: string;
}

export function showScopePicker(
    dialogSrv: DialogService,
    pickerKind: ContextRoleScopePickerKind,
    existingScope: TypeScopeDTO[] | undefined,
    accountInfo: UserAccountInformationDTO | undefined,
    data: any,
    onAccept: (result: any) => void,
    onCancel: () => void = () => {}
) {
    const dta = {
        pickerKind: pickerKind,
        accountInfo: accountInfo,
        userRoleContextId: data.userRoleContextId,
        userRoleContextProfileId: data.userRoleContextProfileId,
        contextCd: data.contextCd,
        roleCd: data.roleCd,
        existingScope: existingScope,
        filterValues: data.filterValues,
        usage: data.usage,
    };

    const editScopeRef = dialogSrv.open(ContextRoleScopePickerComponent, {
        showHeader: false,
        width: '50%',
        height: '60rem',
        contentStyle: { overflow: 'hidden' },
        data: {
            ...dta,
            doAccept: (result: any) => {
                editScopeRef?.close();
                onAccept(result);
            },
            doCancel: () => {
                editScopeRef?.close();
                onCancel();
            },
        },
    });
}

const ComponentName = 'context-role-scope-picker';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './context-role-scope-picker.component.html',
    styleUrls: ['./context-role-scope-picker.component.scss'],
    providers: [ConfirmationService, DynamicDialogRef],
})
export class ContextRoleScopePickerComponent extends BaseComponent {
    private _afterFirstTime = false;
    private _pickerKind: ContextRoleScopePickerKind;
    private _headerLabel: string;
    private _addButtonLabel: string;
    private _cancelButtonLabel: string;

    isLoadingMeta = false;
    isLoadingCRSPicker = false;
    isLoadingCRSPickerValidation = false;
    options: OptionsMapDTO = {};
    crsPickers: ScopeField[] = [];

    usage?: string;
    contextCd?: string;
    roleCd?: string;
    userRoleContextId?: number;
    userRoleContextProfileId?: number;
    existingScope?: TypeScopeDTO[];
    isExceptions = false;

    filterValues: ContextRoleScopeComponentItemDTO[] = [];

    constructor(
        private readonly _globalsSrv: GlobalsService,
        private readonly _contextRoleSrv: ContextRoleService,
        private readonly _dialogCfg: DynamicDialogConfig,
        toastr: ToastrService,
        metaSrv: MetaService,
        translateSrv: TranslateService,
        location: Location
    ) {
        super(toastr, metaSrv, location, translateSrv);

        this._translateKey = ComponentName;

        this.userRoleContextId = this._dialogCfg.data?.userRoleContextId;
        this.contextCd = this._dialogCfg.data?.contextCd;
        this.roleCd = this._dialogCfg.data?.roleCd;
        this.userRoleContextId = this._dialogCfg.data?.userRoleContextId;
        this.userRoleContextProfileId = this._dialogCfg.data?.userRoleContextProfileId;
        this.existingScope = this._dialogCfg.data?.existingScope;
        this._pickerKind = this._dialogCfg.data?.pickerKind ?? ContextRoleScopePickerKind.Scope;
        this.isExceptions = this._pickerKind === ContextRoleScopePickerKind.Exception;
        this.usage = this._dialogCfg.data?.usage;

        this._headerLabel = this.translateKey(`${this._pickerKind}.header`);
        this._addButtonLabel = this.translateKey(`${this._pickerKind}.addButtonLabel`);
        this._cancelButtonLabel = this.translateKey('cancelButtonLabel');

        this.filterValues = [];
        for (const fv of this._dialogCfg.data?.filterValues ?? []) {
            if (!isAssigned(fv)) {
                break;
            }

            this.filterValues.push(fv);
        }
    }

    get headerLabel(): string {
        return this.translateDirect(this._headerLabel);
    }

    get addButtonLabel(): string {
        return this.translateDirect(this._addButtonLabel);
    }

    get cancelButtonLabel(): string {
        return this.translateDirect(this._cancelButtonLabel);
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingCRSPicker || this.isLoadingCRSPickerValidation;
    }

    private _isValidated = false;
    get isValidated(): boolean {
        return this._isValidated;
    }

    get isAllFilterValues(): boolean {
        return this.filterValues.length >= 4;
    }

    get isSaveEnabled(): boolean {
        return !this.isLoading && this.isValidated && !this.isAllFilterValues;
    }

    get selectedScopes(): PickerTypeScopeDTO[] {
        const valueNull: ContextRoleScopeComponentItemDTO[] = [{ fieldValue: undefined, fieldDisplayValue: undefined }];
        let scopes: TypeScopeDTO[] = [];

        let field1Values = this.crsPickers.length > 0 ? this.crsPickers[0].value ?? valueNull : valueNull;
        let field2Values = this.crsPickers.length > 1 ? this.crsPickers[1].value ?? valueNull : valueNull;
        let field3Values = this.crsPickers.length > 2 ? this.crsPickers[2].value ?? valueNull : valueNull;
        let field4Values = this.crsPickers.length > 3 ? this.crsPickers[3].value ?? valueNull : valueNull;

        field1Values = this.filterValues.length > 0 ? [this.filterValues[0]] : field1Values.length > 0 ? field1Values : valueNull;
        field2Values = this.filterValues.length > 1 ? [this.filterValues[1]] : field2Values.length > 0 ? field2Values : valueNull;
        field3Values = this.filterValues.length > 2 ? [this.filterValues[2]] : field3Values.length > 0 ? field3Values : valueNull;
        field4Values = this.filterValues.length > 3 ? [this.filterValues[3]] : field4Values.length > 0 ? field4Values : valueNull;

        field1Values.forEach((value1) => {
            field2Values.forEach((value2) => {
                field3Values.forEach((value3) => {
                    field4Values.forEach((value4) => {
                        const scope: PickerTypeScopeDTO = {
                            field1Value: value1.fieldValue,
                            field2Value: value2.fieldValue,
                            field3Value: value3.fieldValue,
                            field4Value: value4.fieldValue,
                            field1DisplayValue: value1.fieldDisplayValue,
                            field2DisplayValue: value2.fieldDisplayValue,
                            field3DisplayValue: value3.fieldDisplayValue,
                            field4DisplayValue: value4.fieldDisplayValue,
                        };

                        scopes.push(scope);
                    });
                });
            });
        });

        scopes = scopes.filter(
            (s) =>
                (isAssigned(s.field1Value) && s.field1Value !== '') ||
                (isAssigned(s.field2Value) && s.field2Value !== '') ||
                (isAssigned(s.field3Value) && s.field3Value !== '') ||
                (isAssigned(s.field4Value) && s.field4Value !== '')
        );

        return scopes;
    }

    protected override onLangChanged(): void {
        this.doReadMeta();
    }

    protected override doReadMeta(): void {
        this.readMeta((options) => {
            this.metaOptions = options;
            this.readCRSPicker();
        });
    }

    private joinCIV(picker: ScopeField | undefined): string | undefined {
        return picker?.value
            ?.filter((v) => v.fieldValue?.toLowerCase() !== 'all')
            ?.map((v) => v.fieldValue)
            ?.join();
    }

    private fillPickerRequest(request: ReadContextRoleScopePickerRequestBaseDTO): void {
        if (isAssigned(this.userRoleContextId) && (this.userRoleContextId ?? -1) > -1) {
            request.userRoleContextId = this.userRoleContextId;
        } else {
            request.roleCd = this.roleCd;
            request.contextCd = this.contextCd;
        }

        if (isAssigned(this.userRoleContextProfileId)) request.userRoleContextProfileId = this.userRoleContextProfileId;
        if (isAssigned(this.usage) && this.usage !== '') request.usage = this.usage;
        if (isAssigned(this.existingScope) && (this.existingScope?.length ?? 0) > 0) request.existingScope = cloneDeep(this.existingScope ?? []);

        request.languageCd = this._globalsSrv.language;
    }

    readCRSPicker() {
        if (this.isLoadingCRSPicker) return;

        this.isLoadingCRSPicker = true;
        this.emitOnLoading();

        const request: ReadContextRoleScopePickerRequestDTO = {};

        this.fillPickerRequest(request);

        if (this.crsPickers.length >= 1) request.component1Filter = this.joinCIV(this.crsPickers[0]);
        if (this.crsPickers.length >= 2) request.component2Filter = this.joinCIV(this.crsPickers[1]);
        if (this.crsPickers.length >= 3) request.component3Filter = this.joinCIV(this.crsPickers[2]);
        if (this.crsPickers.length >= 4) request.component4Filter = this.joinCIV(this.crsPickers[3]);

        if (this._afterFirstTime) {
            this.filterValues.forEach((fv, fi) => {
                const fnk = `component${fi + 1}Filter` as AnyKey;
                (request as any)[fnk] = fv;
            });
        }

        this._contextRoleSrv.readContextRoleScopePicker(request).subscribe({
            next: (response) => {
                if (!this.showErrorMessage(response)) {
                    if (isAssigned(response.components)) {
                        const component = lastOrDefault(response.components) ?? { componentNumber: 0 };

                        if (this.crsPickers.length < (response.components?.length ?? 1) - 1) {
                            const rc = response.components ?? [];
                            rc.pop();
                            this.crsPickers = [];
                            rc.forEach((c) => {
                                if (isEmpty(c.currentComponentDisplayValue)) {
                                    c.currentComponentDisplayValue = c.currentComponentValue + ' ZXC';
                                }

                                this.crsPickers.push({
                                    component: c,
                                    isValidated: true,
                                    value: [
                                        {
                                            fieldValue: c.currentComponentValue,
                                            fieldDisplayValue: !isEmpty(c.currentComponentDisplayValue)
                                                ? c.currentComponentDisplayValue
                                                : c.currentComponentValue,
                                        },
                                    ],
                                });
                            });
                        }

                        if (isEmpty(component.currentComponentDisplayValue)) {
                            component.currentComponentDisplayValue = component.currentComponentValue + ' ZXC';
                        }

                        if ((component?.componentNumber ?? 0) > this.crsPickers.length) {
                            this.crsPickers.push({ component: component, isValidated: false });
                        }

                        this.checkValidated();
                    } else {
                        this.toastr.error('Components array has not valid data');
                    }
                }

                this.isLoadingCRSPicker = false;
                this.emitOnLoading();
            },
            error: (error) => {
                this.isLoadingCRSPicker = false;
                this.emitOnLoading();

                this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
            },
        });
    }

    readCRSPickerValidate(additionalScopes: TypeScopeDTO[], onIsValid: () => void) {
        if (this.isLoadingCRSPickerValidation) return;

        if (isAssigned(additionalScopes) && additionalScopes.length > 0) {
            this.isLoadingCRSPickerValidation = true;
            this.emitOnLoading();

            const request: ReadContextRoleScopePickerValidationRequestDTO = {
                additionalScope: moveFieldToFilter(additionalScopes),
            };

            this.fillPickerRequest(request);

            this._contextRoleSrv.readContextRoleScopePickerValidation(request).subscribe({
                next: (response) => {
                    if (!this.showErrorMessage(response)) {
                        if (isAssigned(response.msg) && response.msg !== '') {
                            this.toastr.error(response.msg);
                        } else {
                            onIsValid();
                        }
                    }

                    this.isLoadingCRSPickerValidation = false;
                    this.emitOnLoading();
                },
                error: (error) => {
                    this.isLoadingCRSPickerValidation = false;
                    this.emitOnLoading();

                    this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
                },
            });
        }
    }

    onSelectedValueChange(field: ScopeField, value: ContextRoleScopeComponentItemDTO[]) {
        field.value = value;

        this.crsPickers.splice(field.component?.componentNumber ?? 0, 10000);

        this.readCRSPicker();
    }

    checkValidated() {
        this._isValidated =
            this.selectedScopes.length > 0 && !isAssigned(this.crsPickers.find((c) => c.component?.isRequired === true && c.isValidated !== true));
    }

    onValidatedChange(picker: ScopeField, $e: boolean) {
        picker.isValidated = $e;

        this.checkValidated();
    }

    onCancel() {
        this._dialogCfg.data?.doCancel();
    }

    onSave() {
        const selectedScopes = this.selectedScopes;

        this.readCRSPickerValidate(selectedScopes, () => {
            this._dialogCfg.data?.doAccept(selectedScopes);
        });
    }
}
