import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { isAssigned, showErrorMessage } from '../utils';
import { MetaService, UnknownErrorCallingAPIMessage } from '../services';
import { OptionsMapDTO, ReadMetaRequestDTO, ResponseDTO } from '../dtos';
import { TranslateBaseComponent } from './translate-base.component';

@Component({ selector: 'app-base-componet', template: '' })
export class BaseComponent extends TranslateBaseComponent {
    private _isLoadingMeta = false;

    @Input()
    loading = false;

    @Output()
    onLoading = new EventEmitter<boolean>();

    @Input()
    metaOptions: OptionsMapDTO = {};

    constructor(
        private readonly _toastr: ToastrService,
        private readonly _metaSrv: MetaService,
        private readonly _location: Location,
        translateSrv: TranslateService
    ) {
        super(translateSrv);
    }

    protected emitOnLoading() {
        this.onLoading.emit(this.isLoadingInternal);
    }

    protected get toastr(): ToastrService {
        return this._toastr;
    }

    protected get metaSrv(): MetaService {
        return this._metaSrv;
    }

    protected get categoriesToRead(): string[] {
        return [
            'userType,profileStatus,existingContextRoles,availableContextRoles,aging',
            'booleanOptions,work,contexts,contextRoles,roleStatus,userStatus,approvalStatus,profiles,language',
        ];
    }

    protected readMeta(onFinish?: (options: OptionsMapDTO) => void, categories?: string[], index = 0, options: OptionsMapDTO = {}) {
        const list = categories ?? this.categoriesToRead;
        if (index >= list.length) {
            if (isAssigned(onFinish)) {
                onFinish!(options);
            }
            return;
        }

        this._isLoadingMeta = true;
        this.emitOnLoading();

        const request: ReadMetaRequestDTO = {
            categories: list[index],
            languageCd: this.translateSrv.currentLang,
        };

        this.fillReadMetaRequest(request);

        this._metaSrv.readMeta(request).subscribe({
            next: (response) => {
                this._isLoadingMeta = false;
                this.emitOnLoading();

                const metaOptions = { ...options, ...response.meta };

                this.readMeta(onFinish, categories, index + 1, metaOptions);
            },
            error: (error) => {
                this._isLoadingMeta = false;
                this.emitOnLoading();

                this._toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
            },
        });
    }

    protected doReadMeta(): void {
        this.readMeta((metaOptions) => {
            this.metaOptions = metaOptions;
        });
    }

    protected fillReadMetaRequest(request: ReadMetaRequestDTO): void {}

    protected showErrorMessage(response: ResponseDTO, getMessage?: (message?: string) => string): boolean {
        return showErrorMessage(this._toastr, response, getMessage);
    }

    get isLoadingInternal(): boolean {
        return this._isLoadingMeta;
    }

    get isLoading(): boolean {
        return this.loading || this.isLoadingInternal;
    }

    goBack() {
        this._location.back();
    }
}
