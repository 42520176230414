import { Component } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { isAssigned } from '../../data';

@Component({
    selector: 'app-user-context-role-scope-profile-denied-confirm',
    templateUrl: './user-context-role-scope-profile-denied-confirm.component.html',
    styleUrls: ['./user-context-role-scope-profile-denied-confirm.component.scss'],
    providers: [DynamicDialogRef],
})
export class UserContextRoleScopeProfileDeniedConfirmComponent {
    deniedReason?: string;
    message: string;
    deniedReasonLabel: string;

    constructor(private readonly _dialogCfg: DynamicDialogConfig) {
        this.message = this._dialogCfg.data?.message ?? 'Enter the Denied Reason';
        this.deniedReasonLabel = this._dialogCfg.data?.deniedReasonLabel ?? 'Denied Reason';
    }

    get isDenyValidated() {
        return isAssigned(this.deniedReason) && this.deniedReason !== '';
    }

    onCancel() {
        this._dialogCfg.data?.doCancel();
    }

    onSave() {
        this._dialogCfg.data?.doAccept(this.deniedReason);
    }
}
