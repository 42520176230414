import { UserContextRoleProfilePermissionFieldDTO } from './user-context-role-profile-permission-field-dto';
import { UserContextRoleProfilePermissionValueDTO } from './user-context-role-profile-permission-value-dto';

export interface UserContextRoleProfilePermissionDTO {
    profileCd?: string;
    profileName?: string;
    profileStatus?: string;
    activeTab?: string;
    deniedReason?: string;
    canReceiveNotifications?: boolean;
    canRecertify?: boolean;
    pendingRequestAssignedTillDt?: Date;
    canEdit?: boolean;
    isVisibleReceiveNotifications?: boolean;
    isVisibleEditRecertify?: boolean;

    fields?: UserContextRoleProfilePermissionFieldDTO[];
    values?: UserContextRoleProfilePermissionValueDTO[];
    exceptions?: UserContextRoleProfilePermissionValueDTO[];
}
