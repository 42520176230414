export interface TypeScopeDTO {
    field1Value?: string;
    field2Value?: string;
    field3Value?: string;
    field4Value?: string;
    filter1Value?: string;
    filter2Value?: string;
    filter3Value?: string;
    filter4Value?: string;
    approvedProfiles?: string;
    deniedProfiles?: string;
    isActive?: boolean;
}
