import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    MetaService,
    OptionsMapDTO,
    isAssigned,
    UserContextRoleDTO,
    ContextRoleService,
    SaveUserContextRoleProfileRequestDTO,
    UnknownErrorCallingAPIMessage,
    UserAccountInformationDTO,
    ReadUserContextRoleProfileRequestDTO,
    UserContextRoleProfileStatusDTO,
    BaseComponent,
    doSaveUserContextRoleProfile,
} from '../../data';

const ComponentName = 'user-context-role-scope-profile';

@Component({ template: '' })
export class UserContextRoleScopeProfileBase extends BaseComponent {
    isLoadingMeta = false;
    isLoadingProfilePermissions = false;
    isSavingProfilePermissions = false;
    options: OptionsMapDTO = {};

    requestSubs: Subscription[] = [];

    contextRole?: UserContextRoleDTO;
    accountInfo?: UserAccountInformationDTO;
    profileCd?: string;
    userRoleContextProfileId?: number;
    profile: UserContextRoleProfileStatusDTO = { permissions: [] };

    constructor(
        private readonly _confirmSrv: ConfirmationService,
        private readonly _dialogSrv: DialogService,
        private readonly _contextRoleSrv: ContextRoleService,
        private readonly _dialogRef: DynamicDialogRef,
        private readonly _dialogCfg: DynamicDialogConfig,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(toastr, metaSrv, location, translateSrv);
        this._translateKey = ComponentName;

        this.accountInfo = this._dialogCfg.data?.accountInfo;
        this.contextRole = this._dialogCfg.data?.contextRole;
        this.profileCd = this._dialogCfg.data?.profileCd;
        this.userRoleContextProfileId = this._dialogCfg.data?.userRoleContextProfileId;
    }

    protected get confirmSrv(): ConfirmationService {
        return this._confirmSrv;
    }

    protected get dialogSrv(): DialogService {
        return this._dialogSrv;
    }

    protected get contextRoleSrv(): ContextRoleService {
        return this._contextRoleSrv;
    }

    protected get dialogRef(): DynamicDialogRef {
        return this._dialogRef;
    }

    protected get dialogCfg(): DynamicDialogConfig {
        return this._dialogCfg;
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingProfilePermissions || this.isSavingProfilePermissions;
    }

    protected get userEmail(): string | undefined {
        return this.accountInfo?.userEmail ?? this.contextRole?.information?.userEmail;
    }

    protected override get categoriesToRead(): string[] {
        return ['approvalStatus,profiles,language'];
    }

    protected override onInit(): void {
        super.onInit();
    }

    protected override onDestroy(): void {
        this.requestSubs.forEach((s) => {
            if (isAssigned(s)) {
                s.unsubscribe();
            }
        });

        this.requestSubs = [];

        super.onDestroy();
    }

    protected override onLangChanged(): void {
        super.onLangChanged();

        this.doReadMeta();

        this.readProfilePermissions();
    }

    readProfilePermissions() {
        if (this.requestSubs.length > 1) {
            return;
        }

        this.isLoadingProfilePermissions = true;
        this.emitOnLoading();

        const request: ReadUserContextRoleProfileRequestDTO = {
            userRoleContextProfileId: this.userRoleContextProfileId,
            languageCd: this.translateSrv.currentLang,
        };

        this.requestSubs.push(
            this._contextRoleSrv.readUserContextRoleProfile(request).subscribe({
                next: (response) => {
                    if (this.showErrorMessage(response)) {
                        this.onCancel();
                    }

                    this.profile = response.profile ?? { permissions: [] };
                    this.onReadProfilePermissions();

                    this.isLoadingProfilePermissions = false;
                    this.emitOnLoading();
                },
                error: (error) => {
                    this.isLoadingProfilePermissions = false;
                    this.emitOnLoading();

                    this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
                },
            })
        );
    }

    onReadProfilePermissions(): void {}

    saveProfilePermissions(request: SaveUserContextRoleProfileRequestDTO) {
        if (this.isSavingProfilePermissions) {
            return;
        }

        request.userEmail = this.userEmail;
        request.userRoleContextProfileId = this.userRoleContextProfileId;
        request.isInternal = this.accountInfo?.isInternal;

        doSaveUserContextRoleProfile(
            this.toastr,
            this._contextRoleSrv,
            request,
            (loading) => {
                this.isSavingProfilePermissions = loading;
                this.emitOnLoading();
            },
            (response) => {
                this.onAccept(request.deniedReason);
            }
        );
    }

    onAccept(deniedReason?: string) {
        this._dialogCfg.data?.doAccept(deniedReason);
    }

    onCancel() {
        this._dialogCfg.data?.doCancel();
    }
}
