export const ENTranslation = {
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    filterByLabel: 'Filter by',
    paginatorLabel: 'Page {currentPage} of {totalPages} ({totalRecords} entries)',
    from: 'From',
    to: 'To',
    indefinite: 'Indefinite',
    syncB2CNeeded: 'B2C account synchronization is required',
    email: 'Email',
    secondaryEmail: 'Secondary Email',
    name: 'Name',
    userType: 'User Type',
    preferredLanguage: 'Preferred Language',
    primaryPhone: 'Phone',
    mobilePhone: 'Mobile Phone',
    fax: 'Fax',
    external: 'External',
    internal: 'Internal',
    viewHistory: 'View History',
    viewAccountHistory: 'Account History',
    viewRoleHistory: 'Role History',
    viewProfileHistory: 'Profile History',
    accessDetails: 'Access Details',
    emailSentSusccess: 'Validation Email was sent successfully! ',
    emailResentError: "Validation Email can't be sent! ",
    eaps: {
        title: 'Extranet Account Provisioning System',
        'with-menu': {
            tabMyWork: {
                label: 'Approvals',
            },
            tabUserDirectory: {
                label: 'User Directory',
            },
            tabReports: {
                label: 'Reports',
            },
            accountMenu: {
                label: '{{ loggedUser.userName }} on behalf of {{ currentUser.userName }} {{{ currentUser.userEmail }}}',
            },
            myAccount: {
                label: 'My Account',
            },
            loginAs: {
                label: 'Login As',
                notification: 'Current user changed to {{ userName }} ({{ userEmail }})',
            },
            returnUser: {
                label: 'Return to {{ userName }}',
            },
        },
    },
    profile: {
        status: {
            pending: 'Pending',
            recertify: 'Recertify',
            approve: 'Approve',
            approved: 'Approved',
            approveAll: 'Approve All',
            deny: 'Deny',
            denied: 'Denied',
            denyAll: 'Deny All',
        },
        disableAutoUpdate: {
            disabled: 'Auto Update Disabled from {{ source }}. Click to enable',
            enabled: 'Auto Update Enabled from {{ source }}. Click to disable',
            confirm: 'This profile is automatically updated from {{ source }}.<br/>Do you want to disable automatic update and permanently DENY profile?',
        },
        profileStatus: 'Status',
        profileName: 'Profile',
        allProfiles: 'All Profiles',
        viewDetails: 'Profile Details',
        viewApprovers: 'Profile Approvers',
        approverName: 'Updated By',
        statusModifiedDate: 'On',
    },
    'account-information-scopes': {
        confirm: {
            yes: 'Yes, Proceed',
            no: 'No, Cancel',
            denyProfile: {
                header: 'Deny PROFILE',
                message: 'Do you want to deny PROFILE?',
                confirm: 'Please confirm you want to DENY last active profile and permanently deprovision the account',
            },
            denyProfiles: {
                header: 'Deny PROFILE',
                message: 'Would you like to take action on multiple profiles at once?',
            },
            approveProfiles: {
                header: 'Approve PROFILES',
                message: 'Would you like to take action on multiple profiles at once?',
            },
            denyScope: {
                header: 'Deny SCOPE',
                message: 'Would you like to take action on multiple scopes at once?',
            },
            approveScope: {
                header: 'Approve SCOPE',
                message: 'Would you like to take action on multiple scopes at once?',
            },
            denyAll: {
                header: 'Deny All PROFILEs and SCOPEs',
                message: 'Would you like to take action on all profiles and all scopes at once?',
            },
            approveAll: {
                header: 'Approve All PROFILEs and SCOPEs',
                message: 'Would you like to take action on all profiles and all scopes at once?',
            },
        },
    },
    'account-information': {
        header: 'User Account',
        canSyncB2C: 'Sync B2C',
        userStatus: 'Status',
        userRoles: 'Select Role',
        roleName: 'Role',
        roleStatus: 'Status',
        roleAttributes: 'Role Attributes',
        roleTimespan: 'Role Timespan',
        roleIdbApps: 'IDB Applications',
        missingAccountActivation: 'Re-send Account Activation Link',
        addRoleButton: {
            label: 'Add New Role',
        },
        addRequestRoleButton: {
            label: 'Request New Role',
        },
        editRoleButton: {
            label: 'Edit Role',
        },
        deleteRoleButton: {
            label: 'Delete Role',
            title: 'The role {{ roleName }} will be permanently deleted. If this is the only active role, the account would be deprovisioned resulting in the loss of access to some IDB applications. Are you sure you want to continue?',
        },
        viewIdbAppsButton: {
            showMore: 'Show More...',
            showLess: 'Show Less...',
        },
        role: {
            scopes: {
                scopesProfiles: 'Scopes & Profile Authorizations',
                affiliationsProfiles: 'Affiliations & Profile Authorizations',
                profiles: 'Profile Authorizations',
            },
        },
    },
    'context-role-scope-picker': {
        cancelButtonLabel: 'Cancel',
        scope: {
            header: 'Add Scope',
            addButtonLabel: 'Add New Scope',
        },
        affiliation: {
            header: 'Add Affiliation',
            addButtonLabel: 'Add New Affiliation',
        },
        exception: {
            header: 'Add Exception',
            addButtonLabel: 'Add New Exception',
        },
    },
    'context-role-scope-picker-field': {
        all: 'All',
        remove: 'Remove',
        allSelected: {
            placeHolder: 'All {{ value }} selected',
        },
        selectAdditional: {
            placeHolder: 'Select additional {{ value }}',
        },
        selectingNotAllowed: {
            placeHolder: 'Selecting {{ value }} not allowed',
        },
        selectThe: {
            placeHolder: 'Select the {{ value }}',
        },
    },
    'external-account-request': {
        title: 'External User Account Request',
        nextButton: {
            label: 'Next',
        },
    },
    'external-account-request-confirm': {
        header: 'Extranet Terms and Conditions',
        acceptCheckbox: {
            label: 'Accept terms and conditions',
        },
        acceptButton: {
            label: 'Accept',
        },
        declineButton: {
            label: 'Go Back',
        },
    },
    'external-account-request-created': {
        title: 'External User Account Request',
        sendVerification: {
            label: 'Send Verification E-Mail to AAA@BBB.CCC',
        },
        changeEmail: {
            label: 'Change E-Mail Address',
        },
    },
    'external-account-request-resend-link': {
        header: 'Re-Send Verification Link',
        userEmail: {
            label: 'Email',
        },
        sendButton: {
            label: 'Send Email',
        },
    },
    'external-account-request-role-details-editor': {
        and: 'and',
        preferredLanguage: {
            label: 'Preferred Language',
            placeholder: 'Select the Preferred Language',
        },
        relationship: {
            label: 'My Relationship With The Bank Is',
            placeholder: 'Select the Relationship',
        },
        scopesTableLabel: 'Select {{ value }}',
    },
    'external-verify-email': {
        title: 'External User Account Request',
        footer: 'Thanks IADB Team.',
        responseEmpty: 'Sorry, your action generated an error and system support has been notified. Please try later.',
    },
    'user-context-role-editor': {
        title: {
            roleName: 'Edit {{ value }} Role',
            addNewRole: 'Add New Role',
            addNewInternalUser: 'Add New IDB User',
            addNewExternalUser: 'Add New External User',
        },
        noCreateUserPermission: 'No Permissions to create new user',
    },
    'user-context-role-details-editor': {
        preferredLanguage: {
            label: 'Preferred Language',
            placeholder: 'Select the Preferred Language',
        },
        role: {
            title: 'Role Information',
            label: 'Role',
            placeholder: 'Select the Role',
            grantedFrom: 'Role Granted From',
            grantedTo: 'Role Granted To',
            canCreateAccount: 'Allow User To Create Accounts (Champion)',
            canRecertify: 'User Can Receive Approval Related Notifications',
        },
        internal: {
            delete: {
                header: 'Delete Existing Scopes',
                button: 'Remove Scope',
                undoButton: 'Undo Remove Scope',
                warning: 'Only optional scopes with all the profiles denied are available for deletion',
            },
            add: {
                header: 'Edit Scope',
                headerProfile: 'Edit Scope & Profile Authorization',
                warning: 'Please approve at least one profile to be able to create new account',
            },
            addScope: {
                header: 'Edit Scope',
                headerProfile: 'Edit Scope & Profile Authorization',
                button: 'Add Scope',
                warning: 'Please approve at least one profile to be able to create new account',
            },
        },
        external: {
            delete: {
                header: 'Delete Existing Affiliations',
                button: 'Remove Affiliation',
                undoButton: 'Undo Remove Affiliation',
                warning: 'Only optional affiliations with all the profiles denied are available for deletion',
            },
            add: {
                header: 'Edit Affiliation',
                headerProfile: 'Edit Affiliation & Profile Authorization',
                warning: 'Please approve at least one profile to be able to create new account',
            },
            addScope: {
                header: 'Edit Affiliation',
                headerProfile: 'Edit Affiliation & Profile Authorization',
                button: 'Add Affiliation',
                warning: 'Please approve at least one profile to be able to create new account',
            },
        },
    },
    'user-context-role-external-account-editor': {
        title: 'Account Information',
        email: 'Email',
        retypeEmail: 'Retype Email',
        secondaryEmail: 'Secondary Email',
    },
    'user-context-role-external-info-editor': {
        personalTitle: 'Personal Information',
        contactTitle: 'Contact Information',
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        phone: 'Phone',
        mobile: 'Mobile',
        fax: 'Fax',
    },
    'user-context-role-internal-account-editor': {
        title: 'Account Information',
        searchUser: 'Search User',
    },
    'user-context-role-internal-info-editor': {
        title: 'IDB Account Information',
        email: 'Email',
        name: 'Name',
    },
    'my-work': {
        myWorkFilter: {
            label: 'Select a Work filter',
        },
        filterText: {
            label: 'Search Text',
        },
        clearAllFilters: {
            label: 'Clear all Filters',
        },
        columnsTypeMore: {
            label: 'Show More',
        },
        columnsTypeLess: {
            label: 'Show Less',
        },
        export: {
            label: 'Export',
            xslx: {
                label: 'Export to XLSX',
            },
            csv: {
                label: 'Export to CSV',
            },
        },
        columns: {
            userEmail: {
                header: 'Email',
                details: {
                    tooltip: 'Account Details',
                },
            },
            userRoleName: {
                header: 'Role',
                details: {
                    tooltip: 'Role Details',
                },
            },
            userProfileName: {
                header: 'Profile',
                details: {
                    tooltip: 'Profile Details',
                },
                approvers: {
                    tooltip: 'Profile Approvers',
                },
                history: {
                    tooltip: 'Profile History',
                },
            },
            userApprovalStatus: {
                header: 'Approval Status',
                approve: {
                    tooltip: 'Approve',
                },
                deny: {
                    tooltip: 'Deny',
                },
            },
            userFullName: {
                header: 'Name',
            },
            userStatus: {
                header: 'User Status',
            },
            userContextName: {
                header: 'Context',
            },
            userRoleStatus: {
                header: 'Role Status',
            },
            userAssociationValues: {
                header: 'Scope',
            },
            userAssociationType: {
                header: 'Scope Type',
            },
            aging: {
                header: 'Aging',
            },
            approverEmail: {
                header: 'Approver/Assigned To',
            },
            approverRoleName: {
                header: 'Approver/Assigned To Role',
            },
            userApprovalActionDt: {
                header: 'Approval Date',
            },
            userType: {
                header: 'User Type',
            },
            requestDt: {
                header: 'Request Date',
            },
            requestId: {
                header: 'Request #',
            },
        },
    },
    'user-directory': {
        filterText: {
            label: 'Name or Email',
        },
        clearAllFilters: {
            label: 'Clear all Filters',
        },
        columnsTypeMore: {
            label: 'Show More',
        },
        columnsTypeLess: {
            label: 'Show Less',
        },
        export: {
            label: 'Export',
            xslx: {
                label: 'Export to XLSX',
            },
            csv: {
                label: 'Export to CSV',
            },
        },
        addNewUser: {
            label: 'Add New User',
            internal: {
                label: 'New Internal User',
            },
            external: {
                label: 'New External User',
            },
            externalForm: {
                label: 'New External User Account Request',
            },
        },
        columns: {
            userEmail: {
                header: 'Email',
                details: {
                    tooltip: 'Account Details',
                },
            },
            userFullName: {
                header: 'Name',
            },
            userStatus: {
                header: 'User Status',
            },
            userRoleNames: {
                header: 'Active Roles',
            },
            userPendingEmailValidationRoleNames: {
                header: 'Roles Pending Email Validation',
            },
            userScopeValues: {
                header: 'Scope',
            },
            userScopeType: {
                header: 'Scope Type',
            },
            userActiveProfileNames: {
                header: 'Active Profiles',
            },
            userPendingProfileNames: {
                header: 'Pending Profiles',
            },
            userDeniedProfileNames: {
                header: 'Denied Profiles',
            },
            userLastUpdatedDt: {
                header: 'Last Updated Date',
            },
            userIdbApps: {
                header: 'IDB Apps',
            },
            userType: {
                header: 'User Type',
            },
            userIsReadOnly: {
                header: 'Read Only',
            },
            userIsApprover: {
                header: 'Is Approver',
            },
            canEdit: {
                header: 'Can Create Accounts',
            },
        },
    },
    reports: {
        reportFilter: {
            label: 'Select Report',
        },
        roles: {
            label: 'Roles',
        },
        roleApprovers: {
            label: 'Role approvers, Elegible profiles and applications',
        },
        contexts: {
            label: 'Contexts',
        },
        userRoleDataSets: {
            label: 'Role Data Sets',
        },
    },
    'user-role-datasets': {
        userRoleDatasetsFilter: {
            label: 'Select a Role',
        },
    },
    'user-approver-roles': {
        columns: {
            roleName: {
                header: 'Role',
            },
            contextName: {
                header: 'Context',
            },
            profileName: {
                header: 'Profile',
            },
            approverRoleName: {
                header: 'Approver Role Name',
            },
            approverRoleLevel: {
                header: 'Approval Level',
            },
            idbApps: {
                header: 'IDB Apps',
            },
        },
    },
    'user-contexts': {
        columns: {
            contextCode: {
                header: 'Context Code',
            },
            contextName: {
                header: 'Context Name',
            },
            contextDescription: {
                header: 'Context Description',
            },
            contextProfiles: {
                header: 'Context Profiles',
            },
            requestReason: {
                header: 'Request Reason',
            },
            requestExpirationDays: {
                header: 'Request Expiration Days',
            },
            redirectToApplicationName: {
                header: 'Redirect to Application Name',
            },
            redirectUrl: {
                header: 'Redirect URL',
            },
            allowManualUserCreation: {
                header: 'Allow Manual User Creation',
            },
        },
    },
    'user-roles': {
        columns: {
            roleName: {
                header: 'Role',
            },
            contextName: {
                header: 'Context',
            },
            requiredFields: {
                header: 'Required Fields',
            },
            optionalFields: {
                header: 'Optional Fields',
            },
            isReadOnly: {
                header: 'Is Read Only',
            },
            isApprover: {
                header: 'Is Approver',
            },
            isInternal: {
                header: 'Is Internal',
            },
            allowMultipleScopes: {
                header: 'Allow Multiple Scopes or Affiliations',
            },
        },
    },
    'user-history-view-dialog': {
        header: 'User History',
        columns: {
            action: {
                header: 'Action',
            },
            actionPerformedBy: {
                header: 'Action Performed By',
            },
            actionPerformedDt: {
                header: 'Action Performed On',
            },
        },
    },
    'user-context-role-profile-access-details-dialog': {
        header: 'Access Details',
    },
    'user-context-role-scope-profile': {
        approved: {
            permissions: 'Permissions',
        },
        denied: {
            reason: 'Denied Reason',
            enableUpdares: 'Allow profile to be automatically updated by {{ processName }}',
            disableUpdares: 'Disable automatic updates by {{ processName }} and keep profile DENIED',
        },
        pending: {
            keepPendingTill: 'Keep in my workbasket till',
            pendingAssignedTo: '{{ toName }} ({{ toEmail }}) committed to approve this pending request by {{ from }}',
            isAssignedToMe: 'Re-assigne approval to me',
        },
    },
    'login-as-user': {
        header: 'Login As',
        searchUser: 'Please Select an Active User to Login As',
        loginButton: 'Login',
    },
};
